import React, { useContext } from "react"
import Button from "./Button"
import { GlobalDispatchContext } from "../context/GlobalContextProvider"

import { GTMEvent, GTMEventVirtualPage, GTMEventAddToCart } from "../utils/gtm"

const CtaButton = ({
  productCode,
  product_code,
  className,
  children,
  repo,
  ...others
}) => {
  const dispatch = useContext(GlobalDispatchContext)
  const handleClick = () => {
    if (repo) {
      GTMEvent(repo, "click_link", children)
    }
    if (productCode) {
      GTMEventAddToCart(others)
      GTMEventVirtualPage()
    }

    if (others.trackingObj || others.trackingAction || others.trackingLabel) {
      GTMEvent(
        others.trackingObj || "",
        others.trackingAction || others.trackingObj?.seo?.title || "",
        others.trackingLabel || ""
      )
    }

    //HOTFIX 26-07
    if (others.forceOverrideLinkTEMPORARY) {
      let pc = productCode || product_code
      if (!pc) {
        const arr = others.forceOverrideLinkTEMPORARY.split("?product-code=")
        if (arr.length === 2) {
          pc = arr[1]
        }
      }
      dispatch({
        type: "toggleCta",
        payload: {
          productCode: pc,
          force: others.forceOverrideLinkTEMPORARY,
          productVersion:
            others?.trackingObj?.product_info?.product_version ?? "00",
        },
      })
    } else {
      dispatch({
        type: "toggleCta",
        payload: {
          productCode: productCode || product_code,
          productVersion:
            others?.trackingObj?.product_info?.product_version ?? "00",
        },
      })
    }
  }
  //HOTFIX 26-07
  const { href, ...extras } = others
  const othersToPass = others.forceOverrideLinkTEMPORARY ? extras : others
  return (
    <Button
      className={className}
      onClick={handleClick}
      {...othersToPass}
      trackingObj={others.trackingObj}
      trackingAction={others.trackingAction}
      trackingLabel={others.trackingLabel}
    >
      {children || others?.title}
    </Button>
  )
}

export default CtaButton
