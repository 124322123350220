class SnakeCommerceCart {
  static elementsCount = 0
  static snakeCartRefStorage = "_snake_cart_"
  static snakeCartIconRefStorage = "_snake_cart_fanhome_sticky";
  static snakeCartBaseApiUrl = "https://fanhome-snake-apis.herokuapp.com/api"
  //static snakeCartBaseApiUrl = 'https://deagostini-snake-apis-bki-2c1cefb844ef.herokuapp.com/api';
  //static snakeCartBaseApiUrl = 'https://local.snake-api/api';
  static snakeStockAvailability = []
  static snakeItemPurchasable = []

  static init = async () => {
    const addToCartTriggers = document.querySelectorAll(
      ".add-cart-item-trigger"
    )
    if (addToCartTriggers.length) {
      console.debug("[SnakeCommerceCart] Initialized and ready...")
      await this.fetchAvailableStock().then(
        response => (this.snakeStockAvailability = response)
      )
      this.snakeCartRefStorage = this.snakeCartRefStorage + this.#getCountry()
      this.#generateHTMLComponents()
      this.#generateConfirmationModal()
      this.#attachListeners()
      this.#renderStickyCartIcon()
      this.#renderCart()
    }
  }

  static reloadListeners = () => {
    this.#attachAddToCartTriggers()
    this.#renderCart()
  }

  static fetchAvailableStock = async () => {
    //console.log('fetchAvailableStock');
    const stockReferences = document.querySelectorAll(".add-cart-item-trigger")
    let skus = []
    stockReferences.forEach(cta => {
      typeof cta.dataset.sku !== "undefined" ? skus.push(cta.dataset.sku) : null
    })
    const response = await fetch(
      this.snakeCartBaseApiUrl +
        "/v2/core/live/ecommerce-availability?action=build",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          skus: skus,
        }),
      }
    )
    return await response.json()
  }

  static fetchCartItems = async () => {
    try {
      if (this.#cartRefExists()) {
        const response = await fetch(
          this.snakeCartBaseApiUrl +
            "/v4/core/checkout/services/summary/items/" +
            this.#getCartRef() +
            "?action=build"
        )
        return await response.json()
      }
      this.#notify("No Items in the cart...")
      return []
    } catch (error) {
      //console.error('Error during cart fetching:', error);
      return []
    }
  }

  static addCartItem = async sku => {
    localStorage.setItem(this.snakeCartIconRefStorage, true);
    this.#attachLoader();
    this.#renderStickyCartIcon();
    document.getElementById("cart-items").classList.remove("hide")
    try {
      const response = await fetch(
        this.snakeCartBaseApiUrl +
          "/v4/core/checkout/purchase/cart/add?action=build",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            country_slug: this.#getCountry(),
            cart_id: this.#getCartRef(),
            sku: sku,
          }),
        }
      )
      return await response.json()
    } catch (error) {
      //console.error('Error during cart fetching:', error);
      return []
    }
  }

  static removeCartItem = async sku => {
    try {
      this.#attachLoader()
      const response = await fetch(
        this.snakeCartBaseApiUrl +
          "/v4/core/checkout/purchase/cart/remove?action=build",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            country_slug: this.#getCountry(),
            cart_id: this.#getCartRef(),
            sku: sku,
          }),
        }
      )
      return await response.json()
    } catch (error) {
      //console.error('Error during cart fetching:', error);
      return []
    }
  }

  static deleteCartItem = async sku => {
    try {
      this.#attachLoader()
      const response = await fetch(
        this.snakeCartBaseApiUrl +
          "/v4/core/checkout/purchase/cart/delete?action=build",
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            country_slug: this.#getCountry(),
            cart_id: this.#getCartRef(),
            sku: sku,
          }),
        }
      )
      return await response.json()
    } catch (error) {
      //console.error('Error during cart fetching:', error);
      return []
    }
  }

  static #renderCart = async () => {
    const items = await this.fetchCartItems()
    this.#attachLoader()
    const itemsContainer = document.getElementById("cart-items-list")

    if (items?.data?.items_list && items?.data?.items_count > 0) {
      let data = ""
      this.elementsCount = 0

      items.data.items_list.forEach(item => {
        this.elementsCount += item.quantity
        this.snakeItemPurchasable[item.sku] = item.purchasable
        data += this.#generateHTMLItem(item)
      })
      console.log(this.snakeItemPurchasable, "__this.snakeItemPurchasable")
      data = data || window.deaCommerce.cart.noitems

      itemsContainer.innerHTML = data

      document.getElementById("cart-cta-checkout").disabled = false
      document.querySelector("#cart-items p").innerHTML =
        window.deaCommerce.cart.title.replace(/%s/g, this.elementsCount)
      document.querySelector("#toggle-cart-button > small").innerHTML =
        this.elementsCount

      this.#attachGoToCheckoutTriggers()
      this.#attachRemoveFromCartTriggers()
      this.#attachDeleteFromCartTriggers()
    } else {
      if (document.querySelector("#toggle-cart-button > small")) {
        document.querySelector("#toggle-cart-button > small").innerHTML = 0
        document.querySelector("#cart-items p").innerHTML =
          window.deaCommerce.cart.noitems
        itemsContainer.innerHTML = ""
        document.getElementById("hide-cart-stiky").click()
        document.getElementById("cart-cta-checkout").disabled = true
      }
    }

    this.#attachAddToCartTriggers()
    this.#detachLoader()

    const addToCartTriggers = document.querySelectorAll(
      ".add-cart-item-trigger"
    )

    // Herobanner hardcoded spinner -> @todo: to be refactored!!!
    document?.querySelector(".button-spinner-cart")?.classList?.add("d-none")
    document?.querySelector(".button-add-to-cart")?.classList?.remove("d-none")

    addToCartTriggers.forEach(cta => {
      const stockAvailability =
        parseInt(this.snakeStockAvailability[cta.dataset.sku]) || 0
      const isPurchasable = this.snakeItemPurchasable?.[cta.dataset.sku]
      const unlockCta =
        stockAvailability > 0 && (isPurchasable === undefined || isPurchasable)

      cta.toggleAttribute("disabled", !unlockCta)

      if (stockAvailability === 0) {
        var cardNode = cta.closest(".card")
        if (cardNode) {
          var comingSoonNode = cardNode.querySelector(".coming-soon-message")
          if (comingSoonNode?.classList.contains("d-none")) {
            var outOfStockNode = cardNode.querySelector(".out-of-stock-message")
            if (outOfStockNode) {
              outOfStockNode.classList.remove("d-none")
            }
          }
        }
      }
    })
  }

  static #generateHTMLComponents = async () => {
    const layout = `
              <div id="toggle-cart-button" class="item-trigger show-cart-stiky cart-button bounce-in-left hide"><small class="cart-counter">0</small></div>
              <div id="cart-items" class="hide">
                  <div id="cart-cta-toggle"><button class="item-trigger hide-cart-stiky" id="hide-cart-stiky"></button></div>
                  <div id="loading-area">
                      <p></p>
                      <div id="spinner" style="display:none;"><div class="spinner"></div></div>
                      <div id="cart-items-list"></div>
                      <div id="cart-cta">
                          <button id="cart-cta-checkout" class="btn btn--centered btn--small btn__cta btn__primary text-decoration-none ml-1">${window.deaCommerce.cart.cta.label}</button>
                      </div>
                  </div>
              </div>
          `
    var cartContainer = document.createElement("div")
    cartContainer.className = "cart-container"
    cartContainer.innerHTML = layout
    document.body.prepend(cartContainer)
  }

  static #generateHTMLItem = item => {
    console.log(item.purchasable, "__item.purchasable")
    return `
          <div class="cart-item">
              <span class="item-name">${item.name}</small></span>
              <button class="remove-cart-item-trigger btn-console-qty btn__outlined text-decoration-none" data-sku="${
                item.sku
              }">-</button>
              <span class="item-quantity">${item.quantity}</span>
              <button 
                  class="add-cart-item-trigger btn-console-qty btn__outlined text-decoration-none" 
                  data-sku="${item.sku}" 
                  ${item.purchasable === false ? "disabled" : ""}>
                  +
              </button>
              <button class="item-remove-btn delete-cart-item-trigger" data-sku="${
                item.sku
              }"></button>
          </div>
          `
  }

  static #generateConfirmationModal = item => {
    const layout = `
          <div id="deleteItemModal" class="modal">
              <div class="modal-content">
                  <span class="close">&times;</span>
                  <h2 id="modalTitle" class="text-center">${window.deaCommerce.cart.modal.removeitem.title}</h2>
                  <p id="modalMessage" class="text-center">${window.deaCommerce.cart.modal.removeitem.message}</p>
                  <div class="button-container text-center">
                      <button id="confirmNo" class="btn_color_border max-width-none btn btn--centered btn--small text-decoration-none">${window.deaCommerce.cart.modal.removeitem.disallow}</button>
                      <button id="confirmYes" class="max-width-none btn__cta ml-1 btn--centered btn btn__primary btn__outlined btn--small text-decoration-none">${window.deaCommerce.cart.modal.removeitem.allow}</button>
                  </div>
              </div>
          </div>
          `
    var cartContainer = document.createElement("div")
    cartContainer.innerHTML = layout
    document.body.prepend(cartContainer)
  }

  static #attachListeners = () => {
    this.#attachAddToCartTriggers()
    this.#attachRemoveFromCartTriggers()
    this.#attachDeleteFromCartTriggers()
    this.#attachGenericTriggers()
  }

  static #attachGenericTriggers = () => {
    const itemTriggers = document.querySelectorAll(".item-trigger")
    itemTriggers.forEach(elm => {
      elm.addEventListener("click", () => {
        if (elm.classList.contains("show-cart-stiky")) {
          document.getElementById("cart-items").classList.remove("hide")
        }
        if (elm.classList.contains("hide-cart-stiky")) {
          document.getElementById("cart-items").classList.add("hide")
        }
      })
    })
  }

  static #attachAddToCartTriggers = () => {
    const addToCartTriggers = document.querySelectorAll(
      ".add-cart-item-trigger"
    )
    addToCartTriggers.forEach(cta => {
      //cta.removeAttribute('disabled');
      if (this.#hasEventListener(cta, "click") == false) {
        //console.log('[BKI] add new listener..... ADD');
        cta.addEventListener("click", () => {
          this.addCartItem(cta.dataset.sku)
            .then(response => {
              this.#setCartRef(response.data.cart_id)
              this.#renderCart()
            })
            .catch(error => {})
        })
        this.#storeEventListener(cta, "click")
      }
    })
  }

  static #attachRemoveFromCartTriggers = () => {
    const removeFromCartTriggers = document.querySelectorAll(
      ".remove-cart-item-trigger"
    )
    removeFromCartTriggers.forEach(cta => {
      //cta.removeAttribute('disabled');
      if (this.#hasEventListener(cta, "click") == false) {
        //console.log('[BKI] add new listener REMOVE .....');
        cta.addEventListener("click", () => {
          //alert('remove item....');
          this.removeCartItem(cta.dataset.sku)
            .then(response => {
              this.#renderCart()
            })
            .catch(error => {})
        })
        this.#storeEventListener(cta, "click")
      }
    })
  }

  static #attachDeleteFromCartTriggers = () => {
    const deleteFromCartTriggers = document.querySelectorAll(
      ".delete-cart-item-trigger"
    )
    deleteFromCartTriggers.forEach(cta => {
      if (this.#hasEventListener(cta, "click") == false) {
        cta.addEventListener("click", () => {
          this.#confirmationModal().then(result => {
            if (result) {
              this.deleteCartItem(cta.dataset.sku)
                .then(response => {
                  this.#renderCart()
                })
                .catch(error => {
                  //console.error('deleteCartItem error:', error);
                })
            }
          })
        })
        this.#storeEventListener(cta, "click")
      }
    })
  }

  static #attachGoToCheckoutTriggers = () => {
    const cta = document.getElementById("cart-cta-checkout")
    if (this.#hasEventListener(cta, "click") == false) {
      cta.addEventListener("click", () => {
        window.dataLayer.push({
          event: "imAnalyticsEvent",
          event_data: {
            category: "",
            action: "widget_cart",
            label: "Go to Checkout",
          },
        })
        switch (this.#getCountry()) {
          case "es":
            window.location.href =
              "https://suscripcion.planetadeagostini.es/" +
              this.#getCountry() +
              "/purchase?cart_id=" +
              this.#getCartRef()
            break
          case "fr":
            window.location.href =
              "https://abonnement.altaya.fr/" +
              this.#getCountry() +
              "/purchase?cart_id=" +
              this.#getCartRef()
            break
          case "be":
            window.location.href =
              "https://abonnement.altaya.be/" +
              this.#getCountry() +
              "/purchase?cart_id=" +
              this.#getCartRef()
            break
          default:
            window.location.href =
              "https://fanhome.com/" +
              this.#getCountry() +
              "/purchase?cart_id=" +
              this.#getCartRef()
            break
        }
        return false
      })
      this.#storeEventListener(cta, "click")
    }
  }

  static #attachLoader = () =>
    document?.getElementById("loading-area")?.classList?.add("active")

  static #detachLoader = () =>
    document?.getElementById("loading-area")?.classList?.remove("active")

  static #hasEventListener = (element, eventType) => {
    return element.getAttribute("data-event") == eventType ? true : false
  }

  static #storeEventListener = (element, eventType) => {
    element.setAttribute("data-event", eventType)
  }

  static #cartRefExists = () => (this.#getCartRef() !== null ? true : false)

  static #getCartRef = () => {
    const value = localStorage.getItem(this.snakeCartRefStorage)
    return typeof value != null ? value : null
  }
  static #setCartRef = cartId =>
    localStorage.setItem(this.snakeCartRefStorage, cartId)

  static #notify = msg => {
    //console.debug('[Notification] ' + msg)
  }

  static #getCountry = () => {
    var url = window.location.href
    var regex = /(?:http:\/\/|https:\/\/)[^\/]+\/([^\/]+)/
    var matches = url.match(regex)
    if (matches && matches.length > 1) {
      var countryCode = matches[1]
      //console.log("Country Code:", countryCode);
      return countryCode
    }
  }

  static #confirmationModal = message => {
    var modal = document.getElementById("deleteItemModal")
    var confirmYesBtn = document.getElementById("confirmYes")
    var confirmNoBtn = document.getElementById("confirmNo")
    function openModal() {
      modal.style.display = "block"
    }
    function closeModal() {
      modal.style.display = "none"
    }
    return new Promise(function (resolve, reject) {
      openModal()
      function confirmAction() {
        resolve(true)
        closeModal()
      }
      function cancelAction() {
        resolve(false)
        closeModal()
      }

      confirmYesBtn.addEventListener("click", confirmAction)
      confirmNoBtn.addEventListener("click", cancelAction)

      window.addEventListener("click", function (event) {
        if (event.target == modal) {
          cancelAction()
        }
      })
    })
  }

  static #addToCartActionIsAlreadyTriggered = () => {
    if(localStorage.getItem(this.snakeCartIconRefStorage)) {
      return true;
    }
  }

  static #renderStickyCartIcon = () => {
      if(this.#addToCartActionIsAlreadyTriggered()) {
        document.getElementById('toggle-cart-button').classList.remove('hide');
      }
  }
}

export default SnakeCommerceCart
